<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />
  </v-app>
</template>

<script>
/* eslint-disbale */
export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("@/layouts/core/AppBar.vue"),
    DashboardCoreDrawer: () => import("@/layouts/core/Drawer.vue"),
    DashboardCoreView: () => import("@/layouts/MainViewLayout.vue"),
  },

  data: () => ({
    expandOnHover: false,
  }),

  created: async function () {
    try {
      // await this.$store.dispatch("refreshUser"); //Login instead form this
      // await this.$store.dispatch("products/list"); // all product set from here
    } catch (error) {
      if (error.response?.status === 401) {
        this.$store.dispatch("logout");
      }
      this.$store.dispatch("alerts/error", error.response?.data?.message);
    }
  },
};
</script>
